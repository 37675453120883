// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { BANK_TRANSACTIONS_ENDPOINT, BANK_TRANSACTIONS_IDENTIFY, BANK_TRANSACTIONS_CANCEL_IDENTIFY, BANK_TRANSACTIONS_CHANGE, BANK_TRANSACTIONS_CHANGE_TYPE } from "../../../../../../utility/SourceAPI"
import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getBankTransactions = createAsyncThunk('appBankTransactions/getBankTransactions', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?bank_account_id=${data.account}`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`?bank_account_id=${data.account}`))
    return {
        data: response.data
    }
})

export const getBankTransactionsByMovement = createAsyncThunk('appBankTransactions/getBankTransactionsByMovement', async (data) => {
    const response = await axios.get(BANK_TRANSACTIONS_ENDPOINT.concat(`?movement_type=${data.movement_type_id}`))
    return {
        data: response.data
    }
})
export const getBankTransactionsMovements = createAsyncThunk('appBankTransactionsMovements/getBankTransactionsMovements', async () => {
    const response = await axios.get(BANK_TRANSACTIONS_ENDPOINT.concat(`movement-types/`))
    return {
        data: response.data
    }
})
export const getAllBankTransactions = createAsyncThunk('appAllBankTransactions/getAllBankTransactions', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_TRANSACTIONS_ENDPOINT)
    return {
        data: response.data
    }
})

export const getReportTransactions = createAsyncThunk('appReportTransactions/getReportTransactions', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`group-mounth/?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&charge=${data.charge}&deposit=${data.deposit}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`group-mounth/?charge=${data.charge}&deposit=${data.deposit}`))
    return {
        data: response.data
    }
})

export const getFilterBankTransactions = createAsyncThunk('appFilterBankTransactions/getFilterBankTransactions', async (data) => {
    let params = {}
    if (data.companies.length > 0) {
        params = { ...params, company_rfc: data.companies }
    }
    if (data.banks.length > 0) {
        params = { ...params, bank_type: data.banks }
    }
    if (data.intermediary.length > 0) {
        params = { ...params, intermediary_id: data.intermediary }
    }
    if (data.type.length > 0) {
        params = { ...params, is_out: data.type }
    }

    const queryString = Object.keys(params)
        .map(key => {
            const values = Array.isArray(params[key]) ? params[key] : [params[key]]
            return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .join('&')
    const url = `${BANK_TRANSACTIONS_ENDPOINT.concat('resume/')}${queryString ? `?${queryString}` : ''}`
    const response = await axios.get(`${url.concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${data.client_name !== '' ? `&client_name=${data.client}` : ''}`)}`)
    return {
        data: response.data
    }
})

export const getBankTransactionsIntermediary = createAsyncThunk('appBankTransactionsIntermediary/getBankTransactionsIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?intermediary_id=${data.id}`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start).concat(`&is_out=false`)}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`?intermediary_id=${data.id}`).concat(`&is_out=false`))
    return {
        data: response.data
    }
})

export const getAllBankTransactionsIntermediary = createAsyncThunk('appAllBankTransactionsIntermediary/getAllBankTransactionsIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?intermediary_id=${data.id}&is_out=false&is_identified=true&order_by_identified=true`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`?intermediary_id=${data.id}&is_out=false&order_by_identified=true`))
    return {
        data: response.data
    }
})

export const getBankTransactionsUnidentified = createAsyncThunk('appBankTransactionsUnidentified/getBankTransactionsUnidentified', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?get_all_unknown=false&is_identified=false&is_out=false`).concat(`&end=${formattedDatePicker(rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`?get_all_unknown=true&is_identified=false&is_out=false`))
    return {
        data: response.data
    }
})

export const getBankTransactionsIdentified = createAsyncThunk('appBankTransactionsIdentified/getBankTransactionsIdentified', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? BANK_TRANSACTIONS_ENDPOINT.concat(`?is_identified=true&is_out=false&order_by_identified=true`).concat(`&end=${formattedDatePicker(rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}`) : BANK_TRANSACTIONS_ENDPOINT.concat(`?is_identified=true&is_out=false&order_by_identified=true`))
    return {
        data: response.data
    }
})

export const addBankTransactions = createAsyncThunk('appBankTransactions/addBankTransactions', async (bankTransaction, { dispatch }) => {
    const response = await axios.post(BANK_TRANSACTIONS_ENDPOINT, bankTransaction, config)
    const account = parseInt(bankTransaction.get("bank_account_id"))
    const data = {
        account
    }
    await dispatch(getBankTransactions(data))
    await dispatch(getBankTransactionsIdentified())
    await dispatch(getBankTransactionsUnidentified())
    return response.data
})

export const updateBankTransactions = createAsyncThunk('appBankTransactions/updateBankTransactions', async (bankTransaction, { dispatch }) => {
    const response = await axios.put(BANK_TRANSACTIONS_ENDPOINT.concat(`change_timestamp/${bankTransaction.id}`), bankTransaction)
    await dispatch(getBankTransactions(bankTransaction))
    return {
        data: response.data
    }
})

export const identifyBankTransaction = createAsyncThunk('appBankTransactions/identifyBankTransaction', async (bankTransaction, { dispatch }) => {
    const id = bankTransaction.get("id")
    const response = await axios.put(BANK_TRANSACTIONS_IDENTIFY.concat(id), bankTransaction)
    await dispatch(getBankTransactionsUnidentified())
    await dispatch(getBankTransactionsIdentified())
    return response.data
})

export const changeBankTransaction = createAsyncThunk('appBankTransactions/changeBankTransaction', async (bankTransaction, { dispatch }) => {
    const id = bankTransaction.get("id")
    const response = await axios.put(BANK_TRANSACTIONS_CHANGE.concat(id), bankTransaction)
    await dispatch(getBankTransactionsUnidentified())
    await dispatch(getBankTransactionsIdentified())
    return response.data
})

export const changeTransactionType = createAsyncThunk('appBankTransactions/changeTransactionType', async (bankTransaction, { dispatch }) => {
    const response = await axios.put(BANK_TRANSACTIONS_CHANGE_TYPE.concat(bankTransaction?.id), bankTransaction)
    await dispatch(getBankTransactionsUnidentified())
    await dispatch(getBankTransactionsIdentified())
    return response.data
})

export const cancelIdentifyBankTransaction = createAsyncThunk('appBankTransactions/cancelIdentifyBankTransaction', async (bankTransaction, { dispatch }) => {
    const response = await axios.put(BANK_TRANSACTIONS_CANCEL_IDENTIFY.concat(bankTransaction))
    await dispatch(getBankTransactionsIdentified())
    await dispatch(getBankTransactionsUnidentified())
    return response.data
})

export const deleteBankTransaction = createAsyncThunk('appBankTransactions/deleteBankTransaction', async (data) => {
    const response = await axios.delete(BANK_TRANSACTIONS_ENDPOINT.concat(`?transaction_id=${data.transactionId}`))
    return response.data
})

export const linkCashCredit = createAsyncThunk('appBankTransactions/linkCashCredit', async (data) => {
    const response = await axios.put(BANK_TRANSACTIONS_ENDPOINT.concat(`linked-cash-credit/${data.transaction_id}?user_activity_id=${data.user_activity_id}`))
    return {
        data: response.data
    }
})

export const appBankTransactionsSlice = createSlice({
    name: 'appBankTransactions',
    initialState: {
        data: [],
        reportTransactions: [],
        allBankTransactions: [],
        filterBankTransactions: [],
        transactionsUnidentified: [],
        transactionsIdentified: [],
        movements: [],
        transactionsByMovement: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBankTransactions.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getReportTransactions.fulfilled, (state, action) => {
            state.reportTransactions = action.payload.data
        })
        builder.addCase(getAllBankTransactions.fulfilled, (state, action) => {
            state.allBankTransactions = action.payload.data
        })
        builder.addCase(getBankTransactionsUnidentified.fulfilled, (state, action) => {
            state.transactionsUnidentified = action.payload.data
        })
        builder.addCase(getBankTransactionsIdentified.fulfilled, (state, action) => {
            state.transactionsIdentified = action.payload.data
        })
        builder.addCase(getBankTransactionsIntermediary.fulfilled, (state, action) => {
            state.transactionsIntermediary = action.payload.data
        })
        builder.addCase(getAllBankTransactionsIntermediary.fulfilled, (state, action) => {
            state.alltransactionsIntermediary = action.payload.data
        })
        builder.addCase(getBankTransactionsMovements.fulfilled, (state, action) => {
            state.movements = action.payload.data
        })
        builder.addCase(getFilterBankTransactions.fulfilled, (state, action) => {
            state.filterBankTransactions = action.payload.data
        })
        builder.addCase(getBankTransactionsByMovement.fulfilled, (state, action) => {
            state.transactionsByMovement = action.payload.data
        })
    }
}
)

export default appBankTransactionsSlice.reducer